<template>
  <div class="GL_declareForm Gl_input">
    <!-- <Crumbs :title="'工业设计中心情况 '"></Crumbs> -->
    <Crumbs :title="'设计创新中心情况 '"></Crumbs>
    <Form
      class="form"
      ref="formValidate"
      :rules="ruleValidate"
      :model="formValidate"
      :label-width="0"
    >
      <Row>
        <Col class="col_unit" span="24">单位：万元、平方米、个、%</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>设计创新中心名称
          </Col>
          <Col class="m_col" span="20">
            <Input
              :maxlength="25"
              v-model.trim="
                formValidate.industrialDesignCenter.designCenterName
              "
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 基本情况 -->
      <Row>
        <Col span="24">基本情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>成立时间
          </Col>
          <Col class="m_col" span="8">
            <DatePicker
              type="date"
              :value="formValidate.industrialDesignCenter.establishedTime"
              @on-change="
                formValidate.industrialDesignCenter.establishedTime = $event
              "
              placeholder="选择时间"
            ></DatePicker>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>场所面积</span>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="industrialDesignCenter.siteArea"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialDesignCenter.siteArea"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>资产总额
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateFloatingNumber"
              prop="industrialDesignCenter.propertyTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.industrialDesignCenter.propertyTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>职工人数</span>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignCenter.employeeCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignCenter.employeeCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>运营模式
          </Col>
          <Col class="m_col m_padding" span="20">
            <RadioGroup
              size="large"
              v-model="formValidate.industrialDesignCenter.operationPattern"
            >
              <Radio label="1">独立核算</Radio>
              <Radio label="2">非独立核算</Radio>
            </RadioGroup>
          </Col>
        </Row>
      </div>
      <!-- 管理人员 -->
      <Row>
        <Col span="24">管理人员</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>人员
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>姓名
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>职务
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>联系手机
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>电子邮箱
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>负责人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.industrialDesignCenter.principalName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.industrialDesignCenter.principalPost"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="industrialDesignCenter.principalPhone"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.industrialDesignCenter.principalPhone"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="industrialDesignCenter.principalEmail"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.industrialDesignCenter.principalEmail"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>联系人
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.industrialDesignCenter.contactName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="20"
              v-model.trim="formValidate.industrialDesignCenter.contactPost"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateCellPhoneNumber"
              prop="industrialDesignCenter.contactPhone"
            >
              <Input
                :maxlength="20"
                v-model="formValidate.industrialDesignCenter.contactPhone"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="5">
            <FormItem
              :rules="ruleValidate.validateMailbox"
              prop="industrialDesignCenter.contactEmail"
            >
              <Input
                :maxlength="50"
                v-model="formValidate.industrialDesignCenter.contactEmail"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 专业人员 -->
      <Row>
        <Col span="24">专业人员</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>设计创新团队人数
          </Col>
          <Col class="m_col" span="20">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignCenter.businessCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignCenter.businessCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>其中：设计相关学科本科及以上学历人数</span>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignCenter.educationDutyCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignCenter.educationDutyCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>占设计团队比例</span>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialDesignCenter.educationDuty"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.industrialDesignCenter.educationDuty"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>具有设计相关学科专业技术职称（职业资格）的人数</span>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="industrialDesignCenter.technicianCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.industrialDesignCenter.technicianCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col m_title" span="4">
            <span class="redStar">*</span>
            <span>占设计团队比例</span>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="industrialDesignCenter.technicianProportion"
            >
              <Input
                :maxlength="6"
                v-model="
                  formValidate.industrialDesignCenter.technicianProportion
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 硬件条件 -->
      <Row>
        <Col span="24">硬件设备</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="8">
            <Icon
              @click="add('hardwareFacilityList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>仪器设备名称
            </span>
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>台（套）数
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>价值
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .hardwareFacilityList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="8">
            <Icon
              @click="reduce('hardwareFacilityList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.apparatusName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="
                `resourceAndPerfInfo.hardwareFacilityList[${index}].numberOfUnits`
              "
            >
              <Input
                :maxlength="11"
                v-model="item.numberOfUnits"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`resourceAndPerfInfo.hardwareFacilityList[${index}].cost`"
            >
              <Input :maxlength="13" v-model="item.cost" class="unit_input" />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 软件条件 -->
      <Row>
        <Col span="24">软件条件</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="8">
            <Icon
              @click="add('softwareList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>软件名称
            </span>
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>数量（套）
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>价值
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo.softwareList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="8">
            <Icon
              @click="reduce('softwareList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.softwareName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              :prop="`resourceAndPerfInfo.softwareList[${index}].quantity`"
            >
              <Input
                :maxlength="11"
                v-model="item.quantity"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="8">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              :prop="`resourceAndPerfInfo.softwareList[${index}].cost`"
            >
              <Input :maxlength="13" v-model="item.cost" class="unit_input" />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 近三年指标 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>近三年主要指标
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2022年
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2023年
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>2024年前三季度
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>设计投入总额
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="inputInfoList[0].inputTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.inputInfoList[0].inputTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="inputInfoList[1].inputTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.inputInfoList[1].inputTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="inputInfoList[2].inputTotal"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.inputInfoList[2].inputTotal"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>占企业设计研发支出比重
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="inputInfoList[0].expenseProportion"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.inputInfoList[0].expenseProportion"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="inputInfoList[1].expenseProportion"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.inputInfoList[1].expenseProportion"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePercentageThanZero"
              prop="inputInfoList[2].expenseProportion"
            >
              <Input
                :maxlength="6"
                v-model="formValidate.inputInfoList[2].expenseProportion"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>其中：设计人员经费支出
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="inputInfoList[0].expenditureExpend"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.inputInfoList[0].expenditureExpend"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="inputInfoList[1].expenditureExpend"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.inputInfoList[1].expenditureExpend"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validateThanZero"
              prop="inputInfoList[2].expenditureExpend"
            >
              <Input
                :maxlength="13"
                v-model="formValidate.inputInfoList[2].expenditureExpend"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>承担设计项目数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].assumeProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].assumeProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].assumeProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].assumeProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].assumeProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].assumeProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar">*</span>其中：完成项目数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].finishProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].finishProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].finishProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].finishProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].finishProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].finishProjectCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>产业化项目数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].industrializationProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.inputInfoList[0].industrializationProjectCount
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].industrializationProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.inputInfoList[1].industrializationProjectCount
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].industrializationProjectCount"
            >
              <Input
                :maxlength="8"
                v-model="
                  formValidate.inputInfoList[2].industrializationProjectCount
                "
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>专利数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].patentLicensingCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].patentLicensingCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].patentLicensingCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].patentLicensingCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].patentLicensingCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].patentLicensingCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar">*</span>其中：发明专利(授权数)
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].inventPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].inventPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].inventPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].inventPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].inventPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].inventPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span
            >实用新型(授权数)
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].practicalPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].practicalPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].practicalPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].practicalPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].practicalPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].practicalPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span
            >外观设计(授权数)
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].appearancePatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].appearancePatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].appearancePatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].appearancePatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].appearancePatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].appearancePatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>版权数（经登记数）
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].softPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].softPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].softPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].softPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].softPatentCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].softPatentCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>设计标准制定数
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].designStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].designStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].designStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].designStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].designStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].designStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            style="justify-content: flex-start;"
            class="m_col m_title"
            span="6"
          >
            <span class="redStar">*</span>其中：国际或国家标准
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].nationalStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].nationalStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].nationalStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].nationalStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].nationalStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].nationalStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>行业标准
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].industryStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].industryStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].industryStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].industryStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].industryStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].industryStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
        <Row type="flex" class="m_row">
          <Col
            class="m_col m_title"
            style="justify-content: flex-start;"
            span="6"
          >
            <span class="redStar" style="margin-left:3em">*</span>地方或团体标准
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[0].localStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[0].localStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[1].localStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[1].localStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
          <Col class="m_col" span="6">
            <FormItem
              :rules="ruleValidate.validatePositiveInteger"
              prop="inputInfoList[2].localStandardCount"
            >
              <Input
                :maxlength="8"
                v-model="formValidate.inputInfoList[2].localStandardCount"
                class="unit_input"
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <!-- 近三年设计成果获奖情况 -->
      <Row>
        <Col span="24">近三年设计成果获奖情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('awardsInfoList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>获奖作品
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>奖项名称
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>获得时间
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>授奖部门(或机构)
          </Col>
        </Row>
        <Row
          type="flex"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .awardsInfoList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('awardsInfoList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.awardsWorks"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model.trim="item.awardsName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :options="pickerOptions"
              :value="item.obtainTime"
              @on-change="item.obtainTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="50"
              v-model.trim="item.authorizationDepartment"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 近三年开展公共服务情况 -->
      <Row>
        <Col span="24">近三年开展公共服务情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('serviceList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>服务类型
            </span>
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>项目名称
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>开展时间
          </Col>
          <Col class="m_col m_title" span="6">
            <span class="redStar">*</span>组织部门
          </Col>
        </Row>
        <Row
          v-for="(item, index) in formValidate.resourceAndPerfInfo.serviceList"
          :key="index"
          class="m_row"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('serviceList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="20"
              v-model.trim="item.serviceType"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="15"
              v-model="item.serviceName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="6">
            <DatePicker
              :options="pickerOptions"
              :value="item.startTime"
              @on-change="item.startTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="6">
            <Input
              :maxlength="6"
              v-model="item.department"
              class="unit_input"
            />
          </Col>
        </Row>
      </div>
      <!-- 近三年主要设计成果产业化情况 -->
      <Row>
        <Col span="24">近三年主要设计成果产业化情况</Col>
      </Row>
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title icon_col" span="6">
            <Icon
              @click="add('designResultList')"
              class="icon"
              type="ios-add-circle-outline"
            />
            <span class="text_col">
              <span class="redStar">*</span>项目名称
            </span>
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>客户企业
          </Col>
          <Col class="m_col m_title" span="5">
            <span class="redStar">*</span>完成时间
          </Col>
          <Col class="m_col m_title" span="8">
            <span class="redStar">*</span>产业化效果
          </Col>
        </Row>
        <Row
          type="flex"
          class="m_row"
          v-for="(item, index) in formValidate.resourceAndPerfInfo
            .designResultList"
          :key="index"
        >
          <Col class="m_col icon_col" span="6">
            <Icon
              @click="reduce('designResultList', index)"
              class="icon"
              type="ios-remove-circle-outline"
            />
            <Input
              :maxlength="50"
              v-model.trim="item.projectName"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <Input
              :maxlength="30"
              v-model.trim="item.clientCompany"
              class="unit_input"
            />
          </Col>
          <Col class="m_col" span="5">
            <!-- <Input v-model="item.time" class="unit_input" /> -->
            <DatePicker
              :options="pickerOptions"
              :value="item.finishDeliveryTime"
              @on-change="item.finishDeliveryTime = $event"
              type="date"
              placeholder="选择时间"
              style="width: 200px"
            ></DatePicker>
          </Col>
          <Col class="m_col" span="8">
            <Input
              type="textarea"
              :maxlength="300"
              v-model="item.industrializationResult"
              @on-change="
                item.industrializationResult = $util.ToDBC(
                  item.industrializationResult,
                  $event
                )
              "
              class="unit_input"
              placeholder="简述：项目内容、设计理念、设计创新点和技术水平情况，形成的新产品，为企业带来经济效益和社会效益情况等内容。"
              :autosize="{ minRows: 3, maxRows: 8 }"
            />
          </Col>
        </Row>
      </div>
      <!-- 牵头或参与完成国家、本市重大设计创新项目情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span
            >牵头或参与完成国家、本市重大设计创新项目情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.nationalMajorProgramInfo"
              @on-change="
                formValidate.statusQuoPlan.nationalMajorProgramInfo = $util.ToDBC(
                  formValidate.statusQuoPlan.nationalMajorProgramInfo,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="近三年牵头或参与完成国家、本市重点研发、高质量发展、文化创意、重大工程项目等设计创新工作情况。"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 组织或参与国家、本市重要设计类活动情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span>组织或参与国家、本市重要设计类活动情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.activitySituation"
              @on-change="
                formValidate.statusQuoPlan.activitySituation = $util.ToDBC(
                  formValidate.statusQuoPlan.activitySituation,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="近三年牵头或参与设计创新重要活动，包括国家、本市重要设计类活动情况，承担省部级设计创新课题研究，与中小企业开展设计创新项目合作、为中小企业提供设计创新咨询服务，获得省部级示范认定等情况。"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 发展情况说明 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span>发展情况说明
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.operationInfo"
              @on-change="
                formValidate.statusQuoPlan.operationInfo = $util.ToDBC(
                  formValidate.statusQuoPlan.operationInfo,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点介绍设计创新中心发展经历、组织体系、团队建设、运营模式、主要业绩、管理制度、产学研合作情况，以及必要的佐证材料。另外，企业是否建立技术中心、研发中心，如有，阐述清楚其与设计创新中心的关系情况。（可另附页）"
            ></Input>
          </Col>
        </Row>
      </div>
      <!-- 中心今后三年目标与规划情况 -->
      <div class="m_item">
        <Row type="flex" class="m_row">
          <Col class="m_col m_title" span="24">
            <span class="redStar">*</span>中心今后三年目标与规划情况
          </Col>
        </Row>
        <Row class="m_row textarea_row">
          <Col span="24">
            <Input
              :maxlength="1000"
              v-model="formValidate.statusQuoPlan.targetAndPlan"
              @on-change="
                formValidate.statusQuoPlan.targetAndPlan = $util.ToDBC(
                  formValidate.statusQuoPlan.targetAndPlan,
                  $event
                )
              "
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 8 }"
              placeholder="重点是中心今后三年创新建设、有效投入、设计成果等主要指标，以及组织体系建设、运营模式创新、团队建设、履行设计创新公共服务平台职责等规划和措施等情况。"
            ></Input>
          </Col>
        </Row>
      </div>
    </Form>
    <div class="foot">
      <button v-waves @click="cancel" class="GL_cancel">取消</button>
      <button v-waves @click="save('formValidate')" class="GL_save">
        保存
      </button>
      <button v-waves @click="next('formValidate')" class="GL_next">
        保存并跳转下一步
      </button>
    </div>
  </div>
</template>

<script>
import Crumbs from "../../components/crumbs";
import waves from "../../directive/waves";
export default {
  directives: { waves },
  components: {
    Crumbs
  },
  name: "",
  props: {},
  data() {
    const disabledDate = v => {
      return (
        v.getTime() < new Date("1970-1-1").getTime() ||
        v.getTime() > new Date("2038-1-11").getTime()
      );
    };
    return {
      pickerOptions: {
        disabledDate
      },
      programId: null,
      // countNum: 0,
      formValidate: {
        resourceAndPerfInfo: {
          awardsInfoList: [
            {
              awardsWorks: null,
              awardsName: null,
              obtainTime: null,
              authorizationDepartment: null
            }
          ],
          designResultList: [
            {
              projectName: null,
              clientCompany: null,
              finishDeliveryTime: null,
              industrializationResult: null
            }
          ],
          hardwareFacilityList: [
            {
              apparatusName: null,
              numberOfUnits: null,
              cost: null
            }
          ],
          softwareList: [
            {
              softwareName: null,
              quantity: null,
              cost: null
            }
          ],
          serviceList: [
            {
              serviceType: null,
              serviceName: null,
              startTime: null,
              department: null
            }
          ]
        },
        programId: null,
        pageStatus: 2,
        declareType: 3001,
        statusQuoPlan: {
          //牵头或参与完成国家、本市重大设计创新项目情况
          nationalMajorProgramInfo: null,
          //组织或参与国家、本市重要设计类活动情况
          activitySituation: null,
          //发展情况说明
          operationInfo: null,
          //中心今后三年目标与规划情况
          targetAndPlan: null
        },
        // //工业设计中心情况
        industrialDesignCenter: {
          programId: null,
          designCenterName: null,
          affirmTime: null, //获得区级工业设计中心认定时间
          establishedTime: null, //成立时间
          siteArea: null,
          propertyTotal: null, //资产总额
          employeeCount: null, //职工人数
          operationPattern: null, //运营模式(1:独立核算 2:非独立核算)
          principalName: null,
          principalPost: null,
          principalPhone: null,
          contactName: null,
          contactPost: null,
          contactPhone: null, //联系人电话
          businessCount: null, //工业设计从业人数
          educationDuty: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）和占比
          educationDutyCount: null, //其中：本科及以上学历人员数（含具有技师及以上职业资格人员、高级专业技术职务人员）
          technicianCount: null, //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数
          technicianProportion: null //具有技师（⾼级技师）职业资格的⼈员、⾼级专业技术职务⼈员数及占⽐
        },
        inputInfoList: [
          //投入情况、运行情况
          {
            inputTotal: null, //投入总额
            expenseProportion: null, //占企业设计研发支出比重
            expenditureExpend: null, //其中：设计人员经费支出
            // centerExpenditureExpend: null, //中心运营经费支出
            // groomCost: null, //其中：培训费用
            // serviceOutsourcing: null, //工业设计服务外包额
            assumeProjectCount: null, //承担工业设计项目数
            finishProjectCount: null, //其中：完成项目数
            industrializationProjectCount: null, //产业化项目数
            // intellectualPropertyCount: null, //拥有自主知识产权成果数
            // industrializationResultCount: null, //其中：产业化成果数
            patentLicensingCount: null, //专利授权数
            inventPatentCount: null, //其中:发明专利数
            // copyrightAuthorization: null, //版权授权数
            designStandardCount: null, //设计标准制定数
            nationalStandardCount: null, //国家标准
            industryStandardCount: null, //行业标准
            localStandardCount: null, //地方标准
            year: 2022 //年份
          },
          {
            inputTotal: null,
            expenseProportion: null,
            expenditureExpend: null,
            // centerExpenditureExpend: null,
            // groomCost: null,
            // serviceOutsourcing: null,
            assumeProjectCount: null,
            finishProjectCount: null,
            industrializationProjectCount: null,
            // intellectualPropertyCount: null,
            // industrializationResultCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            // copyrightAuthorization: null,
            designStandardCount: null,
            year: 2023
          },
          {
            inputTotal: null,
            expenseProportion: null,
            expenditureExpend: null,
            // centerExpenditureExpend: null,
            // groomCost: null,
            // serviceOutsourcing: null,
            assumeProjectCount: null,
            finishProjectCount: null,
            industrializationProjectCount: null,
            // intellectualPropertyCount: null,
            // industrializationResultCount: null,
            patentLicensingCount: null,
            inventPatentCount: null,
            // copyrightAuthorization: null,
            designStandardCount: null,
            year: 2024
          }
        ]
      },
      ruleValidate: {
        validateThanZero: [
          // 验证大于等于0的所有数，小数点后保留两位小数
          { validator: this.$util.validateThanZero, trigger: "blur" }
        ],
        validatePositiveInteger: [
          // 验证大于或等于零的整数
          { validator: this.$util.validatePositiveInteger, trigger: "blur" }
        ],
        validateFloatingNumber: [
          // 验证大于可以为负数的浮点数
          { validator: this.$util.validateFloatingNumber, trigger: "blur" }
        ],
        // educationDutyCount
        validatePercentageThanZero: [
          { validator: this.$util.validatePercentageThanZero, trigger: "blur" }
        ],
        validateCellPhoneNumber: [
          { validator: this.$util.validateCellPhoneNumber, trigger: "blur" }
        ],
        validateMailbox: [
          { validator: this.$util.validateMailbox, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    this.programId = this.$store.state.industryProgramId;
    this.formValidate.programId = this.$store.state.industryProgramId;
    this.formValidate.industrialDesignCenter.programId = this.$store.state.industryProgramId;
    this.queryPage();
  },

  mounted() {},
  methods: {
    /**减少产品
     * method reduce product
     * */
    reduce(type, index) {
      switch (type) {
        case "awardsInfoList":
          if (this.formValidate.resourceAndPerfInfo.awardsInfoList.length > 1) {
            this.formValidate.resourceAndPerfInfo.awardsInfoList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "designResultList":
          if (
            this.formValidate.resourceAndPerfInfo.designResultList.length > 1
          ) {
            this.formValidate.resourceAndPerfInfo.designResultList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "hardwareFacilityList":
          if (
            this.formValidate.resourceAndPerfInfo.hardwareFacilityList.length >
            1
          ) {
            this.formValidate.resourceAndPerfInfo.hardwareFacilityList.splice(
              index,
              1
            );
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "softwareList":
          if (this.formValidate.resourceAndPerfInfo.softwareList.length > 1) {
            this.formValidate.resourceAndPerfInfo.softwareList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
        case "serviceList":
          if (this.formValidate.resourceAndPerfInfo.serviceList.length > 1) {
            this.formValidate.resourceAndPerfInfo.serviceList.splice(index, 1);
          } else {
            this.$Message.error("最少一条");
          }

          break;
      }
    },
    /**增加产品
     * method add product
     * */
    add(type) {
      switch (type) {
        case "awardsInfoList":
          this.formValidate.resourceAndPerfInfo.awardsInfoList.push({
            awardsWorks: null,
            awardsName: null,
            obtainTime: null,
            authorizationDepartment: null
          });
          break;
        case "designResultList":
          this.formValidate.resourceAndPerfInfo.designResultList.push({
            projectName: null,
            clientCompany: null,
            finishDeliveryTime: null,
            industrializationResult: null
          });
          break;
        case "hardwareFacilityList":
          this.formValidate.resourceAndPerfInfo.hardwareFacilityList.push({
            apparatusName: null,
            numberOfUnits: null,
            cost: null,
            useInfo: null
          });
          break;
        case "softwareList":
          this.formValidate.resourceAndPerfInfo.softwareList.push({
            softwareName: null,
            quantity: null,
            cost: null,
            useInfo: null
          });
          break;
        case "serviceList":
          this.formValidate.resourceAndPerfInfo.serviceList.push({
            serviceType: null,
            serviceName: null,
            startTime: null,
            department: null
          });
          break;
      }
    },
    /*初始化数据
     *method queryPage
     */
    async queryPage() {
      let res = await this.$http.post(
        "shsj_declare/industralDesign/getIndustralDesignInfo",
        {
          programId: this.programId,
          pageStatus: 2,
          declareType: 3001
        }
      );
      if (res.messageCode === 10000) {
        if (res.result.result.idcInfo != null) {
          this.formValidate.industrialDesignCenter = res.result.result.idcInfo;
          if (res.result.result.idcInfo.inputInfoList.length == 3) {
            this.formValidate.inputInfoList =
              res.result.result.idcInfo.inputInfoList;
          }
        }
        if (res.result.result.resourceAndPerfInfo != null) {
          for (let k in res.result.result.resourceAndPerfInfo) {
            if (
              res.result.result.resourceAndPerfInfo[k] &&
              res.result.result.resourceAndPerfInfo[k].length > 0
            ) {
              this.formValidate.resourceAndPerfInfo[k] =
                res.result.result.resourceAndPerfInfo[k];
            }
          }
        }
        if (res.result.result.plan != null) {
          this.formValidate.statusQuoPlan = res.result.result.plan;
        }
      }
    },

    /*保存表单
     *method saveProgram
     **/
    saveProgram(name, type) {
      this.$refs[name].validate(async valid => {
        if (valid) {
          if (this.formValidate.industrialDesignCenter.affirmTime == "") {
            this.formValidate.industrialDesignCenter.affirmTime = null;
          }
          if (this.formValidate.industrialDesignCenter.establishedTime == "") {
            this.formValidate.industrialDesignCenter.establishedTime = null;
          }
          let { messageCode } = await this.$http.post(
            "/shsj_declare/industralDesign/saveIndustralDesignInfo",
            this.formValidate
          );
          if (messageCode === 10000) {
            this.$Message.success("保存页面成功!");
            if (type == "next") {
              this.$router.push("/declare/Personnel");
            }
          }
        } else {
          return this.$Message.error("内容格式不正确!");
        }
      });
    },
    /**取消保存
     * method cancel
     * */
    cancel() {
      this.$Message.info("取消操作");
      setTimeout(() => {
        if (this.$store.state.industryFromPage == "manage") {
          return this.$router.push("/manage");
        }
        return this.$router.push("/declare");
      }, 1000);
    },
    /**保存
     * method save
     * */
    save(name) {
      this.saveProgram(name, "save");
    },
    /**保存并下一步
     * method save and next
     * */
    next(name) {
      this.saveProgram(name, "next");
    }
  }
};
</script>
<style lang="scss" scoped></style>
